export const environment = {
  production: true,
  loginApiUrl: 'https://webservice.homolog.parcelai.retail-tech.io/api/agent/v1/login',
  paymentApiUrl: 'https://webservice.homolog.parcelai.retail-tech.io/api/agent/v1/payments',
  authorization: 'gingaone:secret',
  username: '123456',
  password: 'secret1',
  cnpj: '12345678000910'
};

